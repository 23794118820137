import React from "react";
import AgencyLogo from '../assets/agencylogo.png';

const TheAgency = () => {
  return (
    <div className="flex flex-col items-center justify-center  text-center p-2 lg:p-6 lg:mt-0 bg-white">
      <h1 className="text-3xl md:text-4xl font-mulish font-semibold tracking-wider leading-relaxed text-[#202022] mb-4">
        OUR EXTENDED SERVICES
      </h1>
      <img src={AgencyLogo} alt="agency logo" />
      <p className="text-md md:text-[16px] lg:text-[16px] text-[#141421] font-mulish font-light leading-relaxed tracking-wide p-4 lg:p-4 lg:px-80 px-5">
        A Direct Sales Agency with team of professional sales experts in UAE for
        the past 40 years holding a network of over 1000 Business Owners,
        Purchasing Officers, Chef, Managers, and Decision Makers for differing
        industries, Horeca , Hospitals & Clinics, Schools & Nurseries, Sport
        Venues, Corporates, Fit out & Interiors, Contracting and MUCH MORE. So
        instead of hiring a salesman on the company payroll and pay for the visa
        expenses, insurance, vacation, etc. they hire The Agency. The Agency
        save the overhead expenses of the direct hiring and assure results.
      </p>
      <p className="text-lg md:text-[1.25em] lg:text-[1.25em] text-[#141421] font-mulish  font-light leading-relaxed p-4 lg:p-4 lg:px-80 px-1">
         THE ONLY B2B DIRECT SALES OUTSOURCING IN UAE
      </p>
      {/* Call to Action */}
      <div className="text-[#2B2D42] font-semibold text-lg">
        <a href="#" className="hover:underline">
          Needs a Sales man!
        </a>{" "}
        <br />
        <a href="#" className="hover:underline">
          Let's Talk!
        </a>
        <br />
        
      </div>
      <a href="http://www.theagencymena.com" className="hover:underline text-blue-500 underline mt-3 text-lg">
      http://www.theagencymena.com
        </a>
    </div>
  );
};

export default TheAgency;
