// import React from 'react';
// import Header from '../components/header';
// import Footer from '../components/footer';


// function Home() {
//   return (
//     <div className="home">
//       <Header />
//       <Footer />
//     </div>
//   );
// }

// export default Home;

import React from 'react';

import Content from '../components/content';
import OurMission from '../components/ourMission';
import OurVision from '../components/ourVision';

import TheAgency from '../components/theAgency';
import Footer from '../components/footer';
import Header from '../components/header';
import FastEasy from '../components/fasteasy';
import MainContent from '../components/mainContent';

const Home: React.FC = () => {
  return (

<div className="min-h-screen relative">
      {/* Sticky Background */}
      <div 
        className="fixed inset-0 bg-cover bg-center z-0"
        style={{ 
          backgroundImage: "url('https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1949&q=80')",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>
      </div>
      
      {/* Scrollable Content */}
      <div className="relative z-10">
        <Header />
        <MainContent />
        
        <div className='bg-white'>
        <Content />
        <div className='flex justify-center'>
        <h1 className="text-3xl md:text-4xl font-mulish font-semibold tracking-wider leading-relaxed text-[#202022] mb-4">
      OUR SERVICES
        </h1>
        </div>
      
        <OurMission />
        <OurVision />
        <FastEasy />
        <TheAgency />
        <Footer />
        </div>
       
      </div>
    </div>

  );
};

export default Home;
