import React from "react";

const Content = () => {
  return (
    <div className="flex flex-col items-center justify-center  text-center p-2 lg:p-6 lg:mt-0 bg-white">
         <h3 className="text-xl md:text-2xl font-mulish font-semibold tracking-wider leading-relaxed text-[#202022] mb-4">
        About Us
      </h3>
      <h1 className="text-3xl md:text-4xl font-mulish font-semibold tracking-wider leading-relaxed text-[#202022] mb-4">
        WHO WE ARE
      </h1>
      <p className="text-lg md:text-[1.25em] lg:text-[1.25em] text-[#141421] font-mulish font-light leading-relaxed tracking-wide p-4 lg:p-4 lg:px-80 px-1">
        Supply is a UAE based food trading company with offices and storage
        facilities in Dubai and Ajman in UAE and in Santiago Chile, and we are a
        proud member of Sheikh Mohamed Bin Rashid Establishment for Small and
        Medium Business (SME). Interfoods Supply Dubai services the GCC region
        and Interfoods Supply Limited-Chile service the South and North America
        region.Our stock includes a wide range of food products. We
        distribute various products. Mainly we deal in wholesale of some of the
        finest quality products, such as Rice, Japanese and Chinese food
        ingredients, Seafood's, Herbs Spices, Sushi items, etc.
      </p>
    
    </div>
  );
};

export default Content;
