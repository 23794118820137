import React from "react";
import Vision from "../assets/vision.jpg"; // Update with actual path

const OurVision: React.FC = () => {
  return (
    <section className="flex flex-col md:flex-row items-center justify-center px-6 py-0 md:py-10   mx-auto md:gap-20">
      {/* Left Side - Image */}
      <div className="md:w-1/3 border-4 border-gray-200">
        <img
          src="https://images.pexels.com/photos/5716001/pexels-photo-5716001.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="Interfoods Infographic"
          className="w-full h-auto"
        />
      </div>

      {/* Right Side - Text Content */}

      <div className="md:w-1/2 text-gray-800 md:pl-10 md:text-left ">
        <h2 className="text-4xl font-semibold  text-gray-900 mb-4 mt-5 lg:mt-0">
          Our Vision
        </h2>
        <p className="leading-relaxed md:text-[16px] text-[#2B2D42] font-normal text-left text-sm font-mulish tracking-wider mb-4 mt-6 p-2.5 lg:p-0 md:text-left ">
        To be the best answer and most reliable source for All the Chefs’ requirements.
        </p>
      </div>
    </section>
  );
};

export default OurVision;
