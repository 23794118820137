import React from "react";

interface MenuItem {
  title: string;
  description: string;
  price: string;
  image: string;
}

const menuItems: MenuItem[] = [
  {
    title: "Filet Mignon",
    description: "Tender, juicy filet mignon cooked to perfection",
    price: "$30",
    image: "https://images.pexels.com/photos/24513648/pexels-photo-24513648/free-photo-of-fried-chicken-with-rice-and-fries.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Lobster Ravioli",
    description: "Homemade ravioli stuffed with lobster meat in a creamy sauce",
    price: "$30",
    image: "https://images.pexels.com/photos/1527603/pexels-photo-1527603.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Chicken Piccata",
    description: "Sautéed chicken breast with a tangy lemon caper sauce",
    price: "$10",
    image: "https://images.pexels.com/photos/8696764/pexels-photo-8696764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    title: "Eggplant Parmesan",
    description: "Layers of breaded eggplant, marinara sauce, and melted cheese",
    price: "$40",
    image: "https://images.pexels.com/photos/19295803/pexels-photo-19295803/free-photo-of-eggplant-rolls-with-basil.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
];

const MainCourses: React.FC = () => {
  return (
    <div className="bg-gray-100 py-16 flex flex-col items-center text-center px-4">
      <h2 className="text-4xl font-bold text-gray-800 mb-8">Main Courses</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12 md:gap-8 max-w-6xl">
        {menuItems.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-2xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105"
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold text-gray-900">{item.title}</h3>
              <p className="text-gray-600 mt-2">{item.description}</p>
              <p className="text-lg font-bold text-yellow-600 mt-4">{item.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainCourses;
