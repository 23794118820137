import React from "react";
import Mission from "../assets/mission.jpg"; // Update with actual path

const OurMission: React.FC = () => {
  return (
    <section className="flex flex-col md:flex-row items-center justify-center px-6 py-0 md:py-10  mx-auto md:gap-20">
      {/* Left Side - Image */}
      {/* <div className="md:hidden lg:hidden">
      <div className="md:w-1/3 border-4 border-gray-200">
        <img
          src={Mission}
          alt="Proclinic Infographic"
          className="w-full h-auto"
        />
      </div>
      </div> */}



      <div className="md:w-1/2 text-gray-800 md:pl-10 md:text-left ">
     
        <h2 className="text-4xl font-semibold  text-gray-900 mb-4 mt-2 lg:mt-0">
          Our Mission
        </h2>
        <p className="leading-relaxed md:text-[16px] text-[#2B2D42] font-normal text-left text-sm font-mulish  tracking-wider mb-4 mt-6 p-2.5 lg:p-0 md:text-left ">
          Keep our ears and eyes open to hear and see what customers need and
          offer them the highest quality products wrapped with Top Quality
          Service. “Value for Money” There is always a valid reason why some of
          our prices may sound high, we don’t believe in Cheap, we rather to
          offer Fair price for Excellent Service and Excellent Quality.
        </p>
      </div>
      {/* Right Side - Text Content */}
      <div className="md:w-1/3 md:border-4 md:border-gray-200 ">
        <img
          src="https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="Interfoods Infographic"
          className="w-full h-auto"
        />
      </div>
    </section>
  );
};

export default OurMission;
