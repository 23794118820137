

import React from 'react';

import OurMission from '../components/ourMission';
import OurVision from '../components/ourVision';

import TheAgency from '../components/theAgency';
import Footer from '../components/footer';
import Header from '../components/header';
import FastEasy from '../components/fasteasy';
import MainContent from '../components/mainContent';
import Content from '../components/menu/content';
import ImageGrid from '../components/menu/menucard';
import Appetizers from '../components/menu/appetitezer';
import MainCourses from '../components/menu/mainCourse';

const Menu: React.FC = () => {
  return (

<div className="min-h-screen relative">
      {/* Sticky Background */}
      <div 
        className="fixed inset-0 bg-cover bg-center z-0"
        style={{ 
          backgroundImage: "url('https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1949&q=80')",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>
      </div>
      
      {/* Scrollable Content */}
      <div className="relative z-10">
        <Header />
        <MainContent />
        
        <div className='bg-white'>
        <Content />
        <ImageGrid />
        <Appetizers />
        <MainCourses />
        <Footer />
        </div>
       
      </div>
    </div>

  );
};

export default Menu;
