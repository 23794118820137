import {
  Facebook,
  Instagram,
  Twitter,
  YouTube,
  Email,
  Phone,
  LocationOn,
} from "@mui/icons-material";
import Logo from "../assets/logo.jpg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-white text-black">
      <div className="bg-blue-500 text-white text-center py-4 text-xl font-bold"></div>
      <div className="container mx-auto px-8 py-12 grid grid-cols-1 md:grid-cols-3 gap-12">
        {/* Company Info */}
        <div className="space-y-6 text-left">
          <img
            src={Logo}
            alt="interfoods supply"
            className="w-28 rounded-lg shadow-md"
          />
          <h2 className="text-2xl font-semibold ">Interfoods Supply</h2>
          <p className="text-md leading-relaxed opacity-90">
            Interfoods Supply – A UAE-based food trading company serving GCC
            & the Americas, specializing in premium rice, seafood, spices &
            more. Proud SME member.
          </p>
        </div>
        {/* Navigation Links */}
        <div>
          <h2 className="text-2xl font-semibold mb-6">Our Company</h2>
          <ul className="space-y-3 text-lg">
            <li><Link to="/menu" className="hover:underline cursor-pointer">Menu </Link></li>
            {/* <li className="hover:underline cursor-pointer">Services</li> */}
            {/* <li className="hover:underline cursor-pointer">Gallery</li> */}
            <li><Link to="/contact" className="hover:underline cursor-pointer">Contact Us</Link> </li>
            <li><Link to="http://www.theagencymena.com" className="hover:underline cursor-pointer">THE AGENCY</Link> </li>
            <li><Link to="https://procclinic.com/" className="hover:underline cursor-pointer">PROC CLINIC</Link> </li>
          </ul>
        </div>
        {/* Contact Info */}
        <div>
          <h2 className="text-2xl font-semibold mb-6">Reach Us</h2>
          <p className="flex items-center gap-3 text-lg">
            <Phone className="text-blue-500" /> (+971)509292765
          </p>
          {/* <p className="flex items-center gap-3 text-lg"><Phone className="text-blue-900" /> Landline: 0291-2955600</p> */}
          <p className="flex items-center gap-3 text-lg">
            <Email className="text-blue-500" /> contactus@interfoodssupply.com
          </p>
          <p className="flex items-center gap-3 text-lg">
            <LocationOn className="text-blue-500" />
            Dubai , UAE
          </p>
          {/* <div className="flex space-x-5 mt-6 text-3xl">
            <Facebook className="cursor-pointer hover:text-blue-300 transition-transform transform hover:scale-110" />
            <Instagram className="cursor-pointer hover:text-pink-300 transition-transform transform hover:scale-110" />
            <Twitter className="cursor-pointer hover:text-blue-400 transition-transform transform hover:scale-110" />
            <YouTube className="cursor-pointer hover:text-red-400 transition-transform transform hover:scale-110" />
          </div> */}
        </div>
      </div>
      <div className="text-center text-black md:text-sm text-xs py-5 bg-neutral-900 bg-opacity-50 mt-10">
        {" "}
        Copyright © 2025 | Interfoods Supply. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
