import React from 'react';

const MainContent: React.FC = () => {
  return (
    <div className="relative h-screen">
      {/* Background image with overlay */}
      {/* <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{ 
          backgroundImage: "url('https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1949&q=80')",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div> */}
      
      {/* Content */}
      <div className="relative flex items-center justify-center h-full ">
        <div className="text-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white leading-tight">
          Interfoods Supply – Connecting Continents,<br />
          Delivering Excellence. Based in the UAE &<br />
          Chile, we supply premium rice, seafood, spices <br />
          & more across the GCC & Americas.


          </h1>
        </div>
      </div>
    </div>
  );
};

export default MainContent;