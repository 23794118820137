import React from "react";
import Image5 from '../../assets/image5.jpg';
import Image6 from '../../assets/image6.jpg';
import Image7 from '../../assets/image7.jpg';
import { Email, LocationOn, Phone } from "@mui/icons-material";

const ImageGrid: React.FC = () => {
  return (
    <div className=" min-h-auto lg:h-72  max-w-auto  flex items-center justify-center p-4  lg:mb-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-auto">
        <img
          src={Image5}
          alt="Image 1"
          className="w-full h-auto rounded-lg shadow-lg lg:mb-4"
        />
        <img
          src={Image6}
          alt="Image 2"
          className="w-full h-auto rounded-lg shadow-lg lg:mb-2"
        />
        <img
          src={Image7}
          alt="Image 3"
          className="w-full h-auto rounded-lg shadow-lg lg:mb-4"
        />
      </div>


    </div>
  );
};

export default ImageGrid;
