import React from "react";

const Content = () => {
  return (
    <div className="flex flex-col items-center justify-center  text-center p-2 lg:p-6 lg:mt-0 bg-white">
         {/* <h1 className="text-xl md:text-2xl font-mulish font-semibold tracking-wider leading-relaxed text-[#202022] mb-4">
        CONTACT
      </h1> */}
      <h1 className="text-2xl md:text-3xl font-mulish font-semibold tracking-wider leading-relaxed text-[#202022] mb-4">
      Contact Us
      </h1>
      <p className="text-lg md:text-[1.25em] lg:text-[1.25em] text-[#141421] font-mulish font-light leading-relaxed tracking-wide p-4 lg:p-4 lg:px-80 px-1">
      For any inquiries, feel free to reach out to us at Interfoods Supply
      </p>
    
    </div>
  );
};

export default Content;
