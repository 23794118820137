import React from "react";
import Image1 from '../../assets/image1.jpg';
import Image2 from '../../assets/image2.jpg';
import Image3 from '../../assets/image3.jpg';

const ImageGrid: React.FC = () => {
  return (
    <div className=" min-h-auto lg:h-72  max-w-auto  flex items-center justify-center p-4  lg:mb-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-auto">
        <img
          src={Image1}
          alt="Image 1"
          className="w-full h-auto rounded-lg shadow-lg lg:mb-4"
        />
        <img
          src={Image2}
          alt="Image 2"
          className="w-full h-auto rounded-lg shadow-lg lg:mb-2"
        />
        <img
          src={Image3}
          alt="Image 3"
          className="w-full h-auto rounded-lg shadow-lg lg:mb-4"
        />
      </div>
    </div>
  );
};

export default ImageGrid;
