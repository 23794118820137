import React from 'react';
import './App.css';
import Home from './pages/home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from './pages/menu';
import Contact from './pages/contact';

function App() {
  return (
    <Router> 
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/contact" element={<Contact />} />
      {/* <Home /> */}
      </Routes>
      </Router>
  );
}

export default App;
