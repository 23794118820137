import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import MainContent from '../components/mainContent';
import Content from '../components/contact/content';
import ImageGrid from '../components/contact/image';
import { Email, LocationOn, Phone } from '@mui/icons-material';


const Contact: React.FC = () => {
  return (

<div className="min-h-screen relative">
      {/* Sticky Background */}
      <div 
        className="fixed inset-0 bg-cover bg-center z-0"
        style={{ 
          backgroundImage: "url('https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1949&q=80')",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>
      </div>
      
      {/* Scrollable Content */}
      <div className="relative z-10">
        <Header />
        <MainContent />  
        <div className='bg-white'>
        <Content />
        <ImageGrid />
        <div className='flex md:flex-row flex-col justify-center gap-4 mb-6 px-4'>
                {/* <h2 className="text-2xl font-semibold mb-6">Reach Us</h2> */}
                <p className="flex items-center gap-3 text-lg">
                  <Phone className="text-blue-500" /> (+971)509292765
                </p>
                {/* <p className="flex items-center gap-3 text-lg"><Phone className="text-blue-900" /> Landline: 0291-2955600</p> */}
                <p className="flex items-center gap-3 text-lg">
                  <Email className="text-blue-500" /> contactus@interfoodssupply.com
                </p>
                <p className="flex items-center gap-3 text-lg">
                  <LocationOn className="text-blue-500" />
                  Dubai , UAE
                </p>
                </div>
        <Footer />
        </div>
       
      </div>
    </div>

  );
};

export default Contact;
