// import React from 'react';
// import Logo from '../assets/logo.jpg';

// const Header: React.FC = () => {
//   const [isMenuOpen, setIsMenuOpen] = React.useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <nav className="bg-white opacity-70 shadow-sm">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex justify-center md:justify-between items-center h-24">
//           <div className="flex justify-center w-full h-24 md:w-auto">
//             <img src={Logo} alt="logo" />
//           </div>
          
//           {/* Mobile menu button */}
//           <div className="md:hidden absolute right-4">
//             <button
//               onClick={toggleMenu}
//               className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
//             >
//               <svg
//                 className="h-6 w-6"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 {isMenuOpen ? (
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M6 18L18 6M6 6l12 12"
//                   />
//                 ) : (
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M4 6h16M4 12h16M4 18h16"
//                   />
//                 )}
//               </svg>
//             </button>
//           </div>
          
//           {/* Desktop menu */}
//           <div className="hidden md:flex space-x-10">
//             <NavLink href="/" label="Home" isActive={true} />
//             <NavLink href="#" label="About" />
//             <NavLink href="#" label="Services" />
//             <NavLink href="#" label="Contact" />
//             <NavLink href="#" label="THE AGENCY" />
//             <NavLink href="#" label="PROC CLINIC" />
//           </div>
//         </div>
//       </div>
      
//       {/* Mobile menu */}
//       {isMenuOpen && (
//         <div className="md:hidden">
//           <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
//             <MobileNavLink href="#" label="Home" isActive={true} />
//             <MobileNavLink href="#" label="About" />
//             <MobileNavLink href="#" label="Services" />
//             <MobileNavLink href="#" label="Contact" />
//             <MobileNavLink href="#" label="THE AGENCY" />
//             <MobileNavLink href="#" label="PROC CLINIC" />
//           </div>
//         </div>
//       )}
      
//       {/* News & Views Banner */}
//       {/* <div className="bg-gray-100 py-4">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <h2 className="text-center text-2xl font-medium text-gray-700">News & Views</h2>
//         </div>
//       </div> */}
//     </nav>
//   );
// };

// interface NavLinkProps {
//   href: string;
//   label: string;
//   isActive?: boolean;
// }

// const NavLink: React.FC<NavLinkProps> = ({ href, label, isActive = false }) => {
//   return (
//     <a
//       href={href}
//       className={`inline-flex items-center px-1 pt-1 text-md font-medium ${
//         isActive 
//           ? 'text-blue-600 border-b-2 border-blue-600' 
//           : 'text-gray-700 hover:text-gray-900 hover:border-b-2 hover:border-gray-300'
//       }`}
//     >
//       {label}
//     </a>
//   );
// };

// const MobileNavLink: React.FC<NavLinkProps> = ({ href, label, isActive = false }) => {
//   return (
//     <a
//       href={href}
//       className={`block px-3 py-2 rounded-md text-base font-medium ${
//         isActive
//           ? 'text-red-600 bg-gray-50'
//           : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'
//       }`}
//     >
//       {label}
//     </a>
//   );
// };

// export default Header;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/logo.jpg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white opacity-70 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-24">
          {/* Logo */}
          <div className="flex items-center">
            <img src={Logo} alt="logo" className="h-16 w-auto" />
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8">
            <NavItem to="/" label="Home" />
            <NavItem to="/menu" label="Menu" />
            {/* <NavItem to="/services" label="Services" /> */}
            <NavItem to="/contact" label="Contact" />
            <NavItem to="http://www.theagencymena.com" label="THE AGENCY" />
            <NavItem to="https://procclinic.com/" label="PROC CLINIC" />
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-gray-700">
              {isMenuOpen ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <MobileNavItem to="/" label="Home" />
            <MobileNavItem to="/menu" label="Menu" />
            {/* <MobileNavItem to="/services" label="Services" /> */}
            <MobileNavItem to="/contact" label="Contact" />
            <MobileNavItem to="http://www.theagencymena.com" label="THE AGENCY" />
            <MobileNavItem to="https://procclinic.com/" label="PROC CLINIC" />
          </div>
        </div>
      )}
    </nav>
  );
};

// Navbar Link for Desktop
const NavItem: React.FC<{ to: string; label: string }> = ({ to, label }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `inline-flex items-center px-1 pt-1 text-md font-medium ${
          isActive
            ? "text-blue-600 border-b-2 border-blue-600"
            : "text-gray-700 hover:text-gray-900 hover:border-b-2 hover:border-gray-300"
        }`
      }
    >
      {label}
    </NavLink>
  );
};

// Navbar Link for Mobile
const MobileNavItem: React.FC<{ to: string; label: string }> = ({ to, label }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `block px-3 py-2 rounded-md text-base font-medium ${
          isActive
            ? "text-red-600 bg-gray-50"
            : "text-gray-700 hover:text-gray-900 hover:bg-gray-50"
        }`
      }
    >
      {label}
    </NavLink>
  );
};

export default Header;
