import React from "react";

const FastEasy = () => {
  return (
    <div className="flex flex-col items-center justify-center  text-center p-2 lg:p-6 lg:mt-0 bg-white">
      <h1 className="text-3xl md:text-4xl font-mulish font-semibold tracking-wider leading-relaxed text-[#202022] mb-4">
        FAST AND EASY
      </h1>
      <p className="text-lg md:text-[1.25em] lg:text-[1.25em] text-[#141421] font-mulish font-light leading-relaxed tracking-wide p-4 lg:p-4 lg:px-80 px-1">
        We import fish and seafood products from different regions in the world
        such as Norway. Denmark, Scotland, India, Indonesia, Sri Lanka, Egypt
        and Tunisia. Our products'​ range varies and not limited to certain
        type, targeting all the HORECA sectors with connections and contracts
        with 5 stars hotels, Supermarkets, catering companies, wholesalers, and
        signature restaurants. We are also registered with all Governmental and
        Semi-Governmental organization in UAE such as Hospitals, Ministries,
        Airlines, Palaces, etc.I'm your About section. Click to edit and
        tell your visitors about how your food service will save them time and
        stress. What makes your service enjoyable to use? What sets your
        business apart from your competition?
      </p>
    </div>
  );
};

export default FastEasy;
