import React from "react";

const appetizers = [
  {
    title: "Gourmet Cheese Platter",
    description: "A selection of premium cheeses and cured meats",
    price: "$10",
    image: "https://images.pexels.com/photos/6004713/pexels-photo-6004713.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  },
  {
    title: "Stuffed Mushrooms",
    description: "Savory mushrooms filled with seasoned breadcrumbs",
    price: "$30",
    image: "https://images.pexels.com/photos/9219074/pexels-photo-9219074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  },
  {
    title: "Bruschetta",
    description: "Smoked Bruschetta and Shellfish Marinated in Lime Juice, Cilantro, Jalapeño, and Garlic",
    price: "$10",
    image: "https://images.pexels.com/photos/5638267/pexels-photo-5638267.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  },
  {
    title: "Spinach Artichoke Dip",
    description: "Spinach Artichoke Dip with xFish and Shellfish Marinated in Lime Juice, Cilantro, Jalapeño, and Garlic",
    price: "$30",
    image: "https://images.pexels.com/photos/6544384/pexels-photo-6544384.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  },
  {
    title: "Caprese Salad",
    description: "Classic Italian salad with fresh mozzarella, tomatoes, and basil",
    price: "$20",
    image: "https://images.pexels.com/photos/8696570/pexels-photo-8696570.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  },
  {
    title: "Shrimp Cocktail",
    description: "Chilled shrimp served with cocktail sauce and lemon wedges",
    price: "$20",
    image: "https://images.pexels.com/photos/20484887/pexels-photo-20484887/free-photo-of-chips-and-seafood-in-glass-on-plate-on-table.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  }
];

const Appetizers = () => {
  return (
    <div className="bg-gray-900 text-white py-12 px-6">
      <h1 className="text-4xl font-bold text-center mb-8">Appetizers</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
        {appetizers.map((item, index) => (
          <div
            key={index}
            className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:scale-105 transition transform duration-300"
          >
            <img src={item.image} alt={item.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h2 className="text-2xl font-semibold">{item.title}</h2>
              <p className="text-gray-300 text-sm mt-2">{item.description}</p>
              <p className="text-yellow-400 font-bold text-lg mt-4">{item.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Appetizers;
